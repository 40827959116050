<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <c-table
          ref="mstTable"
          title="중장비유형"
          tableId="mstTable"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :filtering="false"
          :columns="mstGrid.columns"
          :isExcelDown="false"
          :data="mstGrid.data"
          selection="multiple"
          rowKey="code"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <c-btn label="추가" icon="add" @btnClicked="addrowType" v-if="editable" />
            <c-btn label="저장" icon="save" @btnClicked="saveType" v-if="editable" />
            <c-btn label="삭제" icon="remove" @btnClicked="removeType" v-if="editable" />
          </template>
          <template v-slot:customArea="{ props,col }">
            <template>
              <q-chip
                v-if="col.name==='openIns' && props.row.editFlag != 'C'"
                outline square
                color="blue"
                :clickable="true"
                text-color="white"
                @click.stop="() => { rowClick(props.row) }">
                ▶
              </q-chip>
            </template>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
        <c-table
          ref="grid"
          title="유형별 점검항목"
          tableId="table"
          :columns="grid.columns"
          :data="grid.data"
          selection="multiple"
          rowKey="heqForkLiftTypeItemId"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <!-- <c-btn label="동일구분 점검항목추가" icon="add" @btnClicked="addrowCopy" v-if="editable && selectedRows.code && selectedRows.editFlag != 'C'" /> -->
              <c-btn label="추가" icon="add" @btnClicked="addrowIns" v-if="editable && selectedRows.code && selectedRows.editFlag != 'C'" />
              <c-btn label="저장" icon="save" @btnClicked="saveIns" v-if="editable && selectedRows.code && selectedRows.editFlag != 'C'" />
              <c-btn label="삭제" icon="remove" @btnClicked="removeRowIns" v-if="editable && selectedRows.code && selectedRows.editFlag != 'C'" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'fork-lift-type-manage',
  data() {
    return {
      mstGrid: {
        columns: [
          {
            required: true,
            name: 'codeName',
            field: 'codeName',
            label: '중장비유형',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '순번',
            style: 'width:70px',
            align: 'center',
            type: 'number',
            sortable: false,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width:70px',
            type: 'check',
            true: 'Y',
            false: 'N',
            sortable: false,
          },
          {
            name: 'openIns',
            field: 'openIns',
            label: '점검항목',
            align: 'center',
            style: 'width:80px',
            type: 'custom',
            sortable: false
          },
        ],
        data: [],
      },
      grid: {
        columns: [
          {
            required: true,
            name: 'typeName',
            field: 'typeName',
            label: '구분',
            align: 'left',
            style: 'width:250px',
            type: 'text',
            sortable: false,
          },
          {
            required: true,
            name: 'inspectItem',
            field: 'inspectItem',
            label: '점검항목',
            align: 'left',
            type: 'textarea',
            sortable: true,
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '순번',
            style: 'width:70px',
            align: 'center',
            type: 'number',
            sortable: false,
          },
        ],
        data: [],
      },
      editable: true,
      headerUrl: '',
      listUrl: '',
      selectedRows: {}
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getCodeMst();
    },
    getCodeMst() {
      this.selectedRows = {};
      this.grid.data = [];
      this.rowRemoveSelect();
      this.$http.url = this.$format(selectConfig.sys.code.mst.list.url, 'HEQ_TYPE_CD');
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.mstGrid.data = _result.data;
      },
      () => {
      });
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1');
      if (_table.length > 0) {
        for(let c = 0; c <= _table.length; c++) {
          _table[c].classList.remove('bg-light-blue-1');
        }
      }
    },
    rowClick(row) {
      this.selectedRows = row;
      this.$http.url = selectConfig.sop.heq.inspectitem.list.url;
      this.$http.type = 'GET';
      this.$http.param = {heqTypeCd: this.selectedRows.code};
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    addrowIns() {
      let len = this.grid.data.length + 1;
      this.grid.data.push({
        editFlag: 'C',
        heqForkLiftTypeItemId: uid(),  // 중장비유형별 점검항목일련번호
        heqTypeCd: this.selectedRows.code,  // 중장비유형코드
        typeName: '',  // 구분
        inspectItem: '',  // 자율점검항목
        sortOrder: len,  // 순번
      });
    },
    saveIns() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = transactionConfig.sop.heq.inspectitem.save.url;
            this.$http.type = 'POST';
            this.$http.param = this.grid.data;
            this.$http.request(() => {
              this.rowClick(this.selectedRows);
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    removeRowIns() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = transactionConfig.sop.heq.inspectitem.delete.url;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.rowClick(this.selectedRows);
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs['grid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addrowType() {
      let len = this.mstGrid.data.length + 1;
      this.mstGrid.data.push({
        editFlag: 'C',
        codeGrpCd: 'HEQ_TYPE_CD',  // 코드그룹
        code: uid(),  // 코드
        codeName: '',  // 코드명
        useFlag: 'Y',  // 사용여부
        sortOrder: len,  //
      });
    },
    saveType() {
      if (this.$comm.validTable(this.mstGrid.columns, this.mstGrid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = transactionConfig.sop.heq.hhmtype.save.url;
            this.$http.type = 'POST';
            this.$http.param = this.mstGrid.data;
            this.$http.request(() => {
              this.getCodeMst();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    removeType() {
      let selectData = this.$refs['mstTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = transactionConfig.sop.heq.hhmtype.delete.url;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getCodeMst();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.mstGrid.data = this.$_.reject(this.mstGrid.data, item);
              })
              this.$refs['mstTable'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addrowCopy() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '대상 점검항목을 선택하세요.', // 대상 점검항목을 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.grid.data.splice(selectData[0].sortOrder, 0, {
          editFlag: 'C',
          heqForkLiftTypeItemId: uid(),  // 중장비유형별 점검항목일련번호
          heqTypeCd: this.selectedRows.code,  // 중장비유형코드
          typeName: selectData[0].typeName,  // 구분
          inspectItem: '',  // 자율점검항목
          sortOrder: selectData[0].sortOrder + 1,  // 순번
        });
      }
    }
  }
};
</script>
